import React from 'react';
import { signOut, signIn, useSession } from '@GitStartHQ/next-auth/client';

const Index = () => {
  const [session] = useSession();

  if (session) {
    console.log('Got back session', session);
    return (
      <div>
        <h1>GitStart Hooks Project - {session.user?.email}</h1>
        <button type="button" onClick={() => signOut()}>
          Log Out
        </button>
      </div>
    );
  }
  return (
    <>
      <h1>GitStart Hooks Project</h1>
      <button type="button" onClick={() => signIn('google')}>
        Log In With Google
      </button>
    </>
  );
};

export default Index;
